@tailwind base;
@tailwind components;
@tailwind utilities;
.site_logo {
    width: 116px;
}
.game-item{
  height: 350px !important;
}
.account-wrapper{
  max-width: 968px !important;
  min-height: 600px;
}
.about-description{
  text-transform: unset !important;
  color: rgba(255,255,255,.75) !important;
}
.typeform-embed{
  min-height: 450px !important;
}
@media (max-width: 500px){
  .game-item{
    height: 350px !important;
  }
}